import axios from "axios";
import SecurityPlan from "../data/SecurityPlan";
import SimpleSignal from "simplesignal";

/**
 * <pre>
 * Creates A SecurityPlan Based On the data needed. 
 * Package - stores/loading.  
 * </pre> 
 * @class stores.loading.SecurityPlanService
 */
export default class SecurityPlanService {

  /**
   * Creates an instance of SecurityPlanService
   * @param {string} authBaseUrl
   * @param {string} apiKey
   * @param {string} planBaseUrl 
   * @constructor
   */
  constructor(authBaseUrl, apiKey, planBaseUrl) {
    this.onComplete = new SimpleSignal();

    this._isLoading = false;
    this._isAuthing = false;
    this._memberScriptExists = !!CR.userInfo;

    this.__loginOverride = false;

    this._authClient = axios.create({ baseURL: authBaseUrl, headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true });
    this._planClient = axios.create({
      baseURL: planBaseUrl,
      headers: {
        "X-API-KEY": apiKey,
        "Content-Type": "application/json",
      },
      crossDomain: true,
      withCredentials: true,
    });
  }

  /**
   * Check if user is logged in
   * @returns {boolean}
   * @function isLoggedIn
   * @memberof stores.loading.SecurityPlanService  
   * @instance      
   */
  isLoggedIn() {
    if (!this._memberScriptExists) {
      // eslint-disable-next-line no-console
      console.error("Member script not loaded");
      return false;
    }

    return !!CR.userInfo.id || this.__loginOverride;
  }

  /**
   * Register user to save plan progress
   * @param {string} firstName
   * @param {string} lastName
   * @param {string} email
   * @returns {void}
   * @function register
   * @memberof stores.loading.SecurityPlanService   
   * @instance         
   */
  register(firstName, lastName, email) {
    if (this.isLoggedIn()) {
      const message = "User already logged in";
      // eslint-disable-next-line no-console
      console.error(message);
      return Promise.reject(new Error(message));
    }

    if (this._isAuthing) {
      const message = "Registration in progress";
      // eslint-disable-next-line no-console
      console.error(message);
      return Promise.reject(new Error(message));
    }

    this._isAuthing = true;

    return this._authClient.post("/api/v1.0/authService/register?setCookieHeaders=1", { username: email, firstName, lastName, attributionCode: "I09BPS" })
      .then(() => {
        this._isAuthing = false;        
        return CRUserInfo.refresh();
      }).catch((error) => {
        const message = error ? (error.response && error.response.data && error.response.data.message) || (error.message) : "Unknown error occurred";
        this._isAuthing = false;
        return Promise.reject(new Error(message));
      });
  }

  /**
   * Validate that user is validated
   * @returns {Promise<unknown>} 
   * @function userValidated
   * @memberof stores.loading.SecurityPlanService    
   * @instance            
   */
  userValidated() {
      return CRUserInfo.ready();
  }

  /**
   * Loads plan
   * @function load
   * @memberof stores.loading.SecurityPlanService  
   * @instance          
   */
  load() {
    if (this._isLoading) {
      // eslint-disable-next-line no-console
      console.error("User already loading");
      return Promise.reject(new Error("User already loading"));
    }

    this._isLoading = true;

    // Wait for user info to load and validate
    return this.userValidated()
      .then(() => {
        if (!this.isLoggedIn()) {
          // eslint-disable-next-line no-console
          console.error("User not logged in");
          return Promise.reject(new Error("User not logged in"));
        }
      })
      .then(() => this.getPlan())
      .then((plan) => {
        this._isLoading = false;
        this.onComplete.dispatch(plan);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("Error while loading plan: ", error.stack);
        this._isLoading = false;
        this.onComplete.dispatch(new SecurityPlan());
      });
  }

  /**
   * Resets the plan loaded
   * @function reset
   * @memberof stores.loading.SecurityPlanService   
   * @instance          
   */      
  reset() {
    const empty = new SecurityPlan();
    return this.savePlan(empty);
  }

  /**
   * Get plan from members endpoind
   * @returns {Promise<void>} 
   * @function getPlan
   * @memberof stores.loading.SecurityPlanService 
   * @instance        
   */
  getPlan() {
    // return Promise.resolve(new SecurityPlan());
    return this._planClient.get("/member/products/v2/security-plans/")
      .then(({ data }) => this.parseResponse(data));
  }

  /**
   * Save plan
   * @param {object} plan
   * @returns {void}
   * @function savePlan
   * @memberof stores.loading.SecurityPlanService    
   * @instance        
   */
  savePlan(plan) {
    const { hash, completed } = plan || {};
    if (typeof hash !== "string" || hash === '') {
      const message = "Bad hash";
      // eslint-disable-next-line no-console
      console.log(message);
      return Promise.resolve(plan);
    }

    if (!Array.isArray(completed) || !completed.every((completedItem) => typeof completedItem === "string")) {
      // eslint-disable-next-line no-console
      console.error("Bad completed array");
      return Promise.resolve(plan);
    }

    if (!this.isLoggedIn()) {
      // eslint-disable-next-line no-console
      console.error("User not logged in");
      return Promise.resolve(plan);
    }

    if (this._isLoading) {
      // eslint-disable-next-line no-console
      console.error("User loading");
      return Promise.resolve(plan);
    }

    this._isLoading = true;

    return this._planClient
      .post("/member/products/v2/security-plans/", { securityPlanUrl: hash, actionItemsCompleted: completed })
      .then(() => {
        this._isLoading = false;
      }).catch(() => {
        this._isLoading = false;
      }).then(() => plan);
  }

  /**
   * Parse plan response 
   * @param {object} response
   * @returns {Object} ScurityPlan
   * @function parseResponse
   * @memberof stores.loading.SecurityPlanService    
   * @instance           
   */
  parseResponse(response) {
    // Not exactly sure why this is an array, but apparently it is
    if (!response || !Array.isArray(response)) {
      // eslint-disable-next-line no-console
      console.warn("Unexpected plan response: ", response);
      return;
    }

    try {
      const { securityPlanUrl: hash, actionItemsCompleted } = response[0];

      const completed = actionItemsCompleted || [];

      if (typeof hash !== "string") {
        // eslint-disable-next-line no-console
        console.error("No hash in saved plan");
        return;
      }

      if (!Array.isArray(completed)) {
        // eslint-disable-next-line no-console
        console.error("No completed array in saved plan");
        return;
      }

      return new SecurityPlan(hash, completed);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error while parsing plan: ", error.stack);
      // eslint-disable-next-line no-console
      console.log("Response: ", response);
      return new SecurityPlan();
    }
  }
}
