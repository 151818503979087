/**
 * <pre>
 * Class for a SecurityPlan component in store from retrieved data.
 * Package - stores/data. 
 * </pre>
 * @class stores.data.SecurityPlan
 */
export default class SecurityPlan { 

  /**
   * Creates An Object of SecurityPlan Component.   
   * @param  {string} hash  hash of the plan 
   * @param  {array} completed  ids of completed tools  
   * @constructor          
   */    
  constructor(hash = "", completed = []) {
    this.hash = hash;
    this.completed = completed;
  }
}
