/* eslint-disable no-console */

/**
 * Small plan loading manager
 * This is a separate, small JS file to allow better parallel loading 
 */

import SecurityPlannerConstants from "./constants/SecurityPlannerConstants";
import SecurityPlanService from "./stores/loading/SecurityPlanService";

// This object holds all the content
/**
 * Fetches the plan content from contentful.
 */
window.planInit = function() {
  // Create the plan holder as a global reference so app.js code can access it
  const planService = new SecurityPlanService(
    SecurityPlannerConstants.Plan.AUTH_BASE_URL,
    SecurityPlannerConstants.Plan.API_KEY,
    SecurityPlannerConstants.Plan.PLAN_BASE_URL,
  );

  planService.onComplete.add((initialPlan) => {
    window.planChecked(initialPlan, planService);
  });

  planService.load();
};

/**
 * Fetches the plan content from contentful.
 * @param  {object} initialPlan  
 * @param  {object} planService 
 */
window.planChecked = function(initialPlan, planService) {
  // End; this will signal to the app that it's ok to start
  window.initialPlan = initialPlan;
  window.planService = planService;
};

window.planInit();

/* eslint-enable no-console */
